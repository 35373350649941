/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { spanAllContentStyles } from '../__DO_NOT_ADD_TO_THIS_FOLDER__/gatsby-theme-brisk/components/layout';
import { LinkButton } from '@atlaskit/button/new';
import { ContextualAnalyticsData, SCREEN, FireScreenAnalytics } from '@atlassian/analytics-bridge';
import EmptyState from '@atlaskit/empty-state';
import notFoundImage from '../images/404.png';

const browser = typeof window !== 'undefined' && window;
const primaryAction = <LinkButton href="/">Go home</LinkButton>;

const pageDescription = `Try using search or start again at the home page.`;

const props = {
	header: "We can't find that page.",
	description: pageDescription,
	imageUrl: notFoundImage,
	primaryAction,
};

const Page404: React.ComponentType = () => {
	return browser ? (
		<ContextualAnalyticsData sourceType={SCREEN} sourceName="notFound">
			<FireScreenAnalytics />
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
			<div css={spanAllContentStyles}>
				<EmptyState {...props} />
			</div>
		</ContextualAnalyticsData>
	) : null;
};

export default Page404;
